import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import toastr from 'toastr';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router";
import {addTicket,getSearchTicket} from '../../actions/ticket';
import { checkDriverVehicle } from '../../actions/site';
import {storeCart} from '../../actions/cart';
import { convertToBase64 } from "../../helpers/base64FileHelper";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../../components/Checkout/CheckoutForm';
import CvcFrom from '../../components/Checkout/CvcFrom';
import { PayPalButton } from "react-paypal-button-v2";
import { storeTlcPaypalData,getTransCheckTicket,stripeCardList,stripeCardDelete } from '../../actions/payment';
import NothingFound from '../../shared/nothingFound';
import Searching from '../../shared/searching';
import { allowedExtensions,stripePublishKey,paypalClientId } from '../../helpers';

const SecAddNewTicket = ({type,settings,storeCart}) => {
    const navigate = useNavigate();
    const allowedExts = allowedExtensions();
    const [ticket_type, setTicketType] = useState(type || 'dmv');
    const [ticket_id, setTicketId] = useState(''); 
    const [searchTicketModal, setSearchTicketModal] = useState(false); 
    const [fine_amount, setFineAmount] = useState(0);
    const [note, setNote] = useState('');
    const [image,setImage] = useState(''); 
    
    const [cart_id, setCartId] = useState(localStorage.getItem('cartId'));
    const [isSearch,setIsSearch] = useState(false);
    const [ticketInfo,setTicketInfo] = useState({});
    const [isSearchBtn, setIsSearchBtn] = useState(false);
    const [ticket_ids, setTicketsIds] = useState([]);

    const [isSattle,setIssattle] = useState(false);
    const [types, setTypes] = useState('sattle');
    const [price, setPrice] = useState(settings?.tlc_amount);
    const [trans,setTrans] = useState([]);

    const [imageExt, setImageExt] = useState('');
    const [viewPdf, setViewPdf] = useState('');
    const [viewLightBox, setViewLightBox] = useState({photo: null,isOpen: false})
    const { photo, isOpen } = viewLightBox;

    //Paypal & stripe
    const stripePromise = loadStripe(stripePublishKey());

    //Fees Calculation 
    const [credit_card_fees,setCreditCardFees] = useState('');
    const [platform_fees,setPlatformFees] = useState('');

    const [cards,setCards] = useState([]);
    const [isExisting,setIsExisting] = useState(false);
    const [cardId,setCardId] = useState('');

    const [isDeleteCard,setIsDeleteCard] = useState(false);
    const [deleteCardId,setDeleteCardId] = useState('');
    const [totalCard,setTotalCard] =  useState(0);   
    

    useEffect(() => {
        // Type Check
        let total = 0;
        if(types ==='sattle'){
            total = settings?.tlc_amount;
        }else if(types ==='fight'){
            total = settings?.fight_tlc_amount;
        }
        // For Credit card
        let cc_fees = 0;
        if(settings?.creadit_fee){            
            if(settings?.creadit_fee_type ==='percentage'){
                cc_fees = parseFloat(total)*parseFloat(settings?.creadit_fee)/100;
            }else{
                cc_fees = settings?.creadit_fee
            }
        }
        // For Platform
        let platform_fees = 0;
        if(settings?.processing_fee){            
            if(settings?.processing_fee_type ==='percentage'){
                platform_fees = parseFloat(total)*parseFloat(settings?.processing_fee)/100;
            }else{
                platform_fees = settings?.processing_fee
            }
        }
        setCreditCardFees(cc_fees.toFixed(2));
        setPlatformFees(platform_fees.toFixed(2));
        setPrice((parseFloat(total)+parseFloat(platform_fees)+parseFloat(cc_fees)).toFixed(2)); 
    }, [settings,types]); 

    useEffect(() => {
        if(cards && cards.length>0){
            setIsExisting(true)
        }else{
            setIsExisting(false)
        }
    }, [cards]);

    const handleChange = async (e) => {
      const { name, value } = e.target;    
      setTicketType(value);
      setTicketId('');
      try {
        const data = await checkDriverVehicle();
        if (data.status == 200) {
          if (data.step == 1) {
            return navigate("/add-driver");
          } else if (data.step == 2) {
            return navigate("/add-plate");
          }
        } else {
          return navigate("/add-driver");
        }
      } catch (err) {
        console.log(err.message);
      }
      //setIssattle(true);
    };

    const handleExistChange = (e) => {
        const { name, value } = e.target;  
        if(value === 'existing'){
            setIsExisting(true);
        }else{
            setIsExisting(false);
        }
    };

    const handleCardChange = (e) => {
        const { name, value } = e.target;    
        setCardId(value);
    }

    // const handleRadioChange = (e) => {
    //   const { name, value } = e.target;    
    //   setTypes(value);
    //   if(value === 'sattle'){
    //       setPrice((parseFloat(settings?.tlc_amount)+parseFloat((settings?.creadit_fee)?settings?.creadit_fee:0)+parseFloat((settings?.processing_fee)?settings?.processing_fee:0)).toFixed(2)); 
    //   }else{
    //       setPrice((parseFloat(settings?.fight_tlc_amount)+parseFloat((settings?.creadit_fee)?settings?.creadit_fee:0)+parseFloat((settings?.processing_fee)?settings?.processing_fee:0)).toFixed(2)); 
    //   }
    // };

    const dateFormat = issue_date => {
        let d = new Date(issue_date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d); //short
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${(mo<=9)?'0'+mo:mo}/${da}/${ye}`;
    } 

    // const  closeOneModal = () => {
    //     const modal = document.getElementById('ModalTicketDetails');
    //     modal.classList.remove('show');
    //     modal.setAttribute('aria-hidden', 'true');
    //     modal.setAttribute('style', 'display: none');
    //     const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    //     document.body.removeChild(modalBackdrops[0]);
    // }

    const onSearchTicket = async () => {
      setIsSearchBtn(true);
      if(ticket_id){
        setSearchTicketModal(true);
      }      
      setFineAmount('');
      setIssattle(false);
        try {
            let response = await getSearchTicket({
                ticket_id:ticket_id,
                type: ticket_type
            });
            if (response.status === 200) {
                setTicketInfo((response.data)?response.data[0]:[]); 
                console.log('response',response.data);
                if(response.data && response.data[0]?.fine_amount){
                  setFineAmount((response.data)?response.data[0]?.fine_amount:0);
                }else{
                  setFineAmount((response.data)?response.data[0]?.due_amount:0);
                }                  
                setIsSearch(true);   
                setIsSearchBtn(false);
            }else {
                toastr.error(response.message);
                setIsSearchBtn(false);
                setTicketInfo({});
                setFineAmount('');
            }
        } catch (error) {
            toastr.error(error.message);
            setIsSearchBtn(false);
            setTicketInfo({});
            setFineAmount('');
        }
    }    

    const onFileChange = (e) => {
      if (e.target.files[0]) {
        const ext = e.target.files[0].type.split('/')[1];
        setImageExt(ext);
        if (![...allowedExts].includes(ext)) {   
          toastr.error("Please upload a valid file.");
        } else {
          // getBase64(e.target.files[0]);
          var output = document.getElementById('file-input');
            output.src = URL.createObjectURL(e.target.files[0]);
            output.onload = function() {
              URL.revokeObjectURL(output.src) // free memory
            }
          convertToBase64(e.target.files[0], setImage);
        }
      }
    };
  

    const onSubmit = async () => {
      try {
        let msg = await addTicket({
          summons_number: ticket_id,        
          ticket_type: ticket_type,
          fine_amount: fine_amount,
          image:image
        });
        if (msg.status === 200) {           
          //closeOneModal();  
          setSearchTicketModal(false)
          toastr.success(msg.message);
          return navigate("/ticket-management");           
        } else {
          toastr.error(msg.message);
        }
      } catch (err) {
        toastr.error(err.message);
      }
    } 
    
    const onAddTocart = async () => {
      try {
          let msg = await addTicket({
            summons_number: ticket_id,        
            ticket_type: ticket_type,
            fine_amount: fine_amount,
            image:image
          });
          if (msg.status === 200) {  
            console.log();
              setTicketsIds([
                  {
                    id: msg.data?._id,
                    plate_no: msg.data?.plate_no,
                    ticket_id: msg.data.summons_number,
                    amount: msg.data?.due_amount,
                  },
              ]);     
            toastr.success(msg.message);
          } else {
            toastr.error(msg.message);
          }
      } catch (err) {
          toastr.error(err.message);
      }
  }

  useEffect(() => {
    const insertCart = async () => {
        try {
            let msg = await storeCart({
            cartId : cart_id,    
            ticket_ids: ticket_ids
            });
            if (msg.status === 200) {
            setTicketsIds([]); 
            //closeOneModal();
            setSearchTicketModal(false)
            return navigate("/checkout");
            } else {
            toastr.error(msg.message);
            }
        } catch (err) {
            toastr.error(err.message);
        }
    }
    if(ticket_ids.length>0){
        insertCart();
    }
}, [ticket_ids]); 

useEffect(() => {
  if(localStorage.getItem('cartId')){
    setCartId(localStorage.getItem('cartId'))
  }else{
    localStorage.setItem('cartId', uuidv4());
    setCartId(localStorage.getItem('cartId'))
  }
}, []);

const onSattle = async (type) => {
  if(isSattle){
    setIssattle(false);
  }else{
    setIssattle(true);
  }
  if(type === 1){
    setTypes('sattle');
  }else{
    setTypes('fight');
  }
}
useEffect(() => {
  // Card List
  const loadCardList = async () => {
    try {            
      const data = await stripeCardList({});  
      if(data.status == 200) {
          setCards(data.data);  
          setTotalCard(data.data.length);           
      }    
    } catch(err) {
      console.log(err.message);
    }
  }
  loadCardList();              
}, []); 

// Card Delete
const onStripeCardDelete = async () => {
  setIsDeleteCard(true);
  try {            
      const data = await stripeCardDelete({
          cardId:deleteCardId,
          total:totalCard
      });  
      if(data.status === 200) {
          closeOneModal2();
          setDeleteCardId('');
          setIsDeleteCard(false);
          setCards(data.data);
          setTotalCard(data.data.length);    
      }else{
          setIsDeleteCard(false);
          setDeleteCardId('');
          toastr.error(data.message);
          console.log(data.message);
      }
  } catch(err) {
      toastr.error(err.message);
      setIsDeleteCard(false);
      setDeleteCardId('');
  }
}

const closeOneModal2 = () => {
  const modal = document.getElementById('ModalCardDelete');
  modal.classList.remove('show');
  modal.removeAttribute("role");
  modal.removeAttribute("aria-modal");
  modal.setAttribute('aria-hidden', 'true');
  modal.setAttribute('style', 'display: none');
  const modalBackdrops = document.getElementsByClassName('modal-backdrop');
  document.body.removeChild(modalBackdrops[0]);
  document.body.classList.remove('modal-open');
  document.body.style = '';       
}

useEffect(() => {
  const loadTransCheckTicketData = async () => {
      try {            
        const data = await getTransCheckTicket({
          ticket_id:ticket_id
        });  
        if(data.status == 200) {
            setTrans(data.data)
        }    
      } catch(err) {
        console.log(err.message);
      }
  }
  loadTransCheckTicketData();
}, [ticket_id]);
  
    return (
      <>
      <div>
        <div className='body-layout'>
        <div className='body-content'>
          <div className='user-search-part'>
            <div className='title'>Submit Ticket</div>
          </div>
          <div className='add-new-ticket'>
            <form className='form-content'>
              <div className='form-group'>
                <div className='control-label'>Select Tickets</div>
                <div className='select-section'>
                  <div className='select-ticket'>
                    <input 
                    type="radio" 
                    name="ticket" 
                    className='check-input'
                    value='dmv' 
                    checked={ticket_type === 'dmv'}
                    onChange={ handleChange }
                    />
                    <div className='check-label' htmlFor="ticket">Submit a DMV Ticket </div>
                  </div>
                  <div className='select-ticket'>
                    <input 
                    type="radio" 
                    name="ticket" 
                    className='check-input'
                    value='tlc'
                    checked={ticket_type === 'tlc'}
                    onChange={ handleChange }
                    />
                    <div className='check-label' htmlFor="ticket">Submit TLC Summons</div>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='control-label required'>Ticket Information</div>
                <div className='ticket-info'>
                  <div className='ticket-input'> 
                  <input 
                  className='input' 
                  type="text" 
                  placeholder="Ticket ID"
                  value={ticket_id}
                  onChange={(e) => setTicketId(e.target.value)}
                  /> </div>
                  <div className='input-file'>
                    {
                      (ticket_type && ticket_type === 'dmv') &&
                      <button 
                      className='file-input-label' 
                      type='button'
                      onClick={() => onSearchTicket() }
                      //data-toggle="modal" 
                      //data-target="#ModalTicketDetails"
                      disabled={isSearchBtn}
                      >
                        <i className={(isSearchBtn)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-search mr-2"}></i>Search ticket
                      </button>
                    }
                    {
                      (ticket_type && ticket_type === 'tlc') &&
                      <button 
                      className='file-input-label' 
                      type='button'
                      onClick={() => onSearchTicket() }
                      //data-toggle="modal" 
                      //data-target="#ModalTicketDetails"
                      disabled={isSearchBtn}
                      >
                        <i className={(isSearchBtn)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-search mr-2"}></i>Search ticket
                      </button>
                    }
                  </div>

                </div>
              </div>

            </form>
          </div>
        </div>
       </div>
      </div>
      {
        (searchTicketModal && ticket_id) &&
        <div className="modal fade career-apply-modal searchTicketModal" id="ModalTicketDetails" tabIndex="-1" data-backdrop="static"
        data-keyboard="false">
          <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                  <div className="modal-header">
                      <div className="header-text">
                          <h5 className="modal-title" id="ModalCenterTitle">Ticket Information</h5>
                      </div>
                      <button type="button" className="modal-close" onClick={() => setSearchTicketModal(false)}><i
                          className="fa-light fa-fw fa-times"></i></button>
                  </div>
                  <div className="modal-body payment-modal-part">
                    {
                      (ticket_type && ticket_type === 'dmv')?
                      !isSearchBtn?(
                      <>
                      <div className='details'>
                          <div className='details-card'>
                              <div className='vehicle-name'>{ticketInfo?.summons_number}</div>
                              <div className='vehicle-info'>
                                  <div className='vehicle-number'>{ticketInfo?.plate}</div>
                              </div>
                              {
                                  (ticketInfo && ticketInfo?.summons_number) &&
                                  <div className='vehicle-metalist'>
                                      <div className='meta-item'>
                                      <div className='meta-label'>State</div>
                                      <div className='meta-data'>{ticketInfo?.state}</div>
                                      </div>
                                      <div className='meta-item'>
                                      <div className='meta-label'>License Type</div>
                                      <div className='meta-data'>{ticketInfo?.license_type}</div>
                                      </div>
                                      <div className='meta-item'>
                                      <div className='meta-label'>Issue Date</div>
                                      <div className='meta-data'>{ticketInfo?.issue_date ? dateFormat(ticketInfo?.issue_date) : ''}</div>
                                      </div>
                                      <div className='meta-item'>
                                      <div className='meta-label'>Violation Time</div>
                                      <div className='meta-data'>{ticketInfo?.violation_time}</div>
                                      </div>
                                      <div className='meta-item'>
                                      <div className='meta-label'>Violation</div>
                                      <div className='meta-data'>{(ticketInfo?.violation)?ticketInfo?.violation:ticketInfo?.violation_description}</div>
                                      </div>
                                      <div className='meta-item item-two-third'>
                                      <div className='meta-label'>Fine Amount</div>
                                      <div className='meta-data'>{ticketInfo?.fine_amount}</div>
                                      </div>
                                      <div className='meta-item item-full'>
                                      <div className='meta-label'>Penalty Amount</div>
                                      <div className='meta-data'>{ticketInfo?.penalty_amount}</div>
                                      </div>                       
                                      <div className='meta-item'>
                                      <div className='meta-label'>Interest Amount</div>
                                      <div className='meta-data'>{ticketInfo?.interest_amount}</div>
                                      </div>
                                      <div className='meta-item item-two-third'>
                                      <div className='meta-label'>Reduction Amount</div>
                                      <div className='meta-data'>{ticketInfo?.reduction_amount}</div>
                                      </div>
                                      <div className='meta-item'>
                                      <div className='meta-label'>Payment Amount</div>
                                      <div className='meta-data'>{ticketInfo?.payment_amount}</div>
                                      </div>
                                      <div className='meta-item'>
                                      <div className='meta-label'>Amount Due</div>
                                      {
                                          ticketInfo.is_trans &&
                                          <div className='meta-data'>0</div>
                                      }
                                      {
                                          !ticketInfo.is_trans &&
                                          <div className='meta-data'>{(ticketInfo?.amount_due)?ticketInfo?.amount_due:ticketInfo?.due_amount}</div>
                                      }
                                      </div>
                                      {
                                        ticketInfo?.summons_image &&
                                        <div className='assigned-drivers mb-3 mt-3'>
                                            <div className='driver'>
                                                <div className='driver-image'>
                                                <iframe 
                                                src={ticketInfo?.summons_image} 
                                                //title={ticketInfo?.summons_image.description}
                                                >
                                                </iframe>
                                                </div>
                                            </div>
                                        </div>
                                      }
                                  </div>
                              }
                          </div>
                      </div>
                      {/* Add Others */} 
                      {
                        !ticketInfo &&
                        <div className='form-group'> 
                          <div className='control-label'>Fine Amount</div>                       
                          <div className='ticket-info'>
                            <div className='ticket-input'> 
                            <input 
                            className='input' 
                            type="text" 
                            placeholder="Fine Amount" 
                            readOnly={(ticketInfo && ticketInfo?.type === 'API')?true:false}
                            value={fine_amount}
                            onChange={(e) => setFineAmount(e.target.value)}
                            /> </div>                  
                          </div>
                        </div>
                      }                      
                      {
                        (!isSearch || !ticketInfo?.summons_number || !ticketInfo?.summons_image) &&
                        <>
                        <div className='ticket-info mb-3'>
                          <div className='input-file upload'>
                            <input 
                            name="image" 
                            type="file" 
                            onChange={onFileChange}
                            id="file-input" 
                            className='upload-input'
                            accept="image/jpeg,image/gif,image/png,application/pdf"
                            />
                            <label className='file-input-label' htmlFor="file-input"><i className="fa-regular fa-upload mr-2"></i>Upload File</label>
                          </div>
                        </div>
                        </>
                      }

                      <div className='driver-info-content'>
                        {
                          image &&
                          <>
                          <div className='driver-info-title'>Preview</div>
                          <div className="input-file cursor">
                              {
                                (image && (imageExt === 'pdf' || imageExt === 'PDF'))?
                                <img
                                    src='/assets/img/PDF_file_icon.svg.png'
                                    title={image}
                                    onClick={() => setViewPdf(image)}
                                    data-toggle="modal" 
                                    data-target="#pdfModal"
                                />:
                                <img 
                                src={image}   
                                onClick={() => setViewLightBox({ photo:image, isOpen: true })}
                                className="img-thumbnail" 
                                alt="Cinque Terre"/>
                              } 
                            </div>
                          </>
                        }
                      </div>
                      </>
                      ):(<Searching/>)
                      :''
                    }
                    { 
                      (ticket_type && ticket_type === 'tlc')?     
                      ticketInfo?(              
                      (!isSattle) && 
                      <div className='details'>
                          <div className='details-card'>
                              <div className='vehicle-name'>{(ticketInfo?.ticket_number)?ticketInfo?.ticket_number:ticketInfo?.summons_number}</div>
                              <div className='vehicle-info'>
                                  <div className='vehicle-number'>{ticketInfo?.plate}</div>
                              </div>
                              {
                                  (ticketInfo && ticketInfo?.violation_date) &&
                                  <div className='vehicle-metalist'>
                                      <div className='meta-item'>
                                      <div className='meta-label'>Violation Date</div>
                                      <div className='meta-data'>{ticketInfo?.violation_date ? dateFormat(ticketInfo?.violation_date) : ''}</div>
                                      </div>
                                      <div className='meta-item'>
                                      <div className='meta-label'>Violation Location Borough</div>
                                      <div className='meta-data'>{ticketInfo?.violation_location_borough}</div>
                                      </div>
                                      <div className='meta-item'>
                                      <div className='meta-label'>City</div>
                                      <div className='meta-data'>{ticketInfo?.violation_location_city}</div>
                                      </div>
                                      <div className='meta-item'>
                                      <div className='meta-label'>Zip Code</div>
                                      <div className='meta-data'>{ticketInfo?.violation_location_zip_code}</div>
                                      </div>
                                      <div className='meta-item'>
                                      <div className='meta-label'>Violation Details</div>
                                      <div className='meta-data'>{(ticketInfo?.violation_details)?ticketInfo?.violation_details:ticketInfo?.violation_description}</div>
                                      </div>
                                      <div className='meta-item item-two-third'>
                                      <div className='meta-label'>Respondent First Name</div>
                                      <div className='meta-data'>{ticketInfo?.respondent_first_name}</div>
                                      </div>
                                      <div className='meta-item item-full'>
                                      <div className='meta-label'>Respondent Last Name</div>
                                      <div className='meta-data'>{ticketInfo?.respondent_last_name}</div>
                                      </div> 
                                      <div className='meta-item'>
                                      <div className='meta-label'>Respondent Address Borough</div>
                                      <div className='meta-data'>{ticketInfo?.respondent_address_borough}</div>
                                      </div>  
                                      <div className='meta-item'>
                                      <div className='meta-label'>Issueing Agency</div>
                                      <div className='meta-data'>{ticketInfo?.issuing_agency}</div>
                                      </div>
                                      <div className='meta-item'>
                                      <div className='meta-label'>Decision Date</div>
                                      <div className='meta-data'>{ticketInfo?.decision_date ? dateFormat(ticketInfo?.decision_date) : ''}</div>
                                      </div>  
                                      <div className='meta-item'>
                                      <div className='meta-label'>Hearing Date</div>
                                      <div className='meta-data'>{ticketInfo?.hearing_date ? dateFormat(ticketInfo?.hearing_date) : ''}</div>
                                      </div>                      
                                      <div className='meta-item'>
                                      <div className='meta-label'>Paid Amount</div>
                                      <div className='meta-data'>{ticketInfo?.paid_amount}</div>
                                      </div>
                                      <div className='meta-item item-two-third'>
                                      <div className='meta-label'>Penalty Imposed</div>
                                      <div className='meta-data'>{ticketInfo?.penalty_imposed}</div>
                                      </div>
                                      {/* <div className='meta-item'>
                                      <div className='meta-label'>Payment Amount</div>
                                      <div className='meta-data'>{ticketInfo?.payment_amount}</div>
                                      </div> */}
                                      <div className='meta-item'>
                                      <div className='meta-label'>Total Violation Amount</div>
                                      <div className='meta-data'>{(ticketInfo?.total_violation_amount)?ticketInfo?.total_violation_amount:ticketInfo?.due_amount}</div>
                                      </div>
                                  </div>
                              }
                          </div>
                      </div>
                      ):((isSearchBtn)?<Searching/>:(isSattle)?'':'')
                      :''
                    }
                    {
                      ((ticket_type && ticket_type == 'tlc') && isSattle) && 
                      <>
                      <div className='container-fluid'>
                        <div className='row'>
                          <div className='col-lg-12'>
                              <div className='payment-option'>
                                  <div className="title">Payment Summary</div>
                                  <div className="payment-summary">
                                      <div className="summary-list">
                                          <div className="item">
                                              <div className="item-label">Sub total:</div>
                                              {
                                                  (types === 'sattle')?
                                                  <div className="item-data">${settings?.tlc_amount}</div>:
                                                  <div className="item-data">${settings?.fight_tlc_amount}</div>
                                              }
                                          </div>
                                          {
                                              credit_card_fees >0 &&
                                              <div className="item">
                                                  <div className="item-label">Credit Card Fee:</div>
                                                  <div className="item-data">${credit_card_fees}</div>
                                              </div>
                                          }
                                          {
                                          platform_fees >0 &&  
                                          <div className="item">
                                                  <div className="item-label">Processing Fee:</div>
                                                  <div className="item-data">${platform_fees}</div>
                                              </div>
                                          }                                                           
                                      </div>
                                      <div className="summary-total">
                                          <div className="label">Total Amount</div>
                                          <div className="data">${price}</div>
                                      </div>
                                  </div>
                              </div>
                            {/* <div className='payment-heading' style={{display: types === 'sattle' ? 'block' : 'none' }}>
                                <p>**Please Pay ${settings?.tlc_amount} Amount for Sattlement Ticket</p>
                            </div>
                            <div className='payment-heading' style={{display: types === 'fight' ? 'block' : 'none' }}>
                                <p>**Please Pay ${settings?.fight_tlc_amount} Amount for Fight Ticket</p>
                            </div> */}
                          </div>
                          {/* <div className='col-lg-12'>
                            <div className="form-check form-check-inline">
                                <div className="custom-radio mr-3">
                                    <input 
                                    type="radio" 
                                    className="custom-radio-input" 
                                    name="country-type"
                                    value='sattle'
                                    checked={types === 'sattle'}
                                    onChange={ handleRadioChange }
                                    />
                                    <div className="custom-radio-label">Settle</div>
                                </div>
                                <div className="custom-radio">
                                    <input 
                                    type="radio" 
                                    className="custom-radio-input"
                                    name="country-type" 
                                    value='fight'
                                    checked={types === 'fight'}
                                    onChange={ handleRadioChange }
                                    />
                                    <div className="custom-radio-label">Fight</div>
                                </div>
                            </div>
                          </div>*/}
                          <div className='col-lg-5'>
                            {
                              cards && cards.length>0 &&
                              <div className="payment-select">
                                  <div className="radio-group">
                                      <div className="radio">
                                          <input 
                                          type="radio" 
                                          className="radio-input" 
                                          name="card-type"
                                          value="existing"
                                          checked={isExisting}
                                          disabled={cards && cards.length === 0}
                                          onChange={ handleExistChange }
                                          />
                                          <div className="radio-label">
                                              <div className="text">Existing Card</div>
                                              <div className="icon"><i className="fa-regular fa-fw fa-credit-card"></i></div>
                                          </div>
                                      </div>
                                      <div className="radio">
                                          <input 
                                          type="radio" 
                                          className="radio-input" 
                                          name="card-type"
                                          value="new"
                                          checked={!isExisting}
                                          onChange={ handleExistChange }
                                          />
                                          <div className="radio-label">
                                              <div className="text">New Card</div>
                                              <div className="icon"><i className="fa-regular fa-fw fa-credit-card"></i></div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            }
                            {
                              isExisting?
                              <div className="saved-cards">
                                      <div className="title">Saved Cards</div>
                                      <div className="card-list">
                                          <div className="card-item">
                                              {
                                                cards.length > 0 &&
                                                cards.map((item, index) =>
                                                <div className="radio" key={index}>
                                                    <input 
                                                    type="radio" 
                                                    className="radio-input" 
                                                    name="saved-cards" 
                                                    value={item?.id}
                                                    checked={cardId === item?.id}
                                                    onChange={ handleCardChange }
                                                    />
                                                    <div className="radio-label">
                                                        <div className="label-icon">
                                                            {
                                                                (item.card?.brand && item.card?.brand === 'visa') &&
                                                                <div className="icon"><i className="fa-brands fa-fw fa-cc-visa"></i></div>
                                                            }
                                                            {
                                                                (item.card?.brand && item.card?.brand === 'amex') &&
                                                                <div className="icon"><i className="fa-brands fa-fw fa-cc-amex"></i></div>
                                                            }
                                                            {
                                                                (item.card?.brand && item.card?.brand === 'mastercard') &&
                                                                <div className="icon"><i className="fa-brands fa-fw fa-cc-mastercard"></i></div>
                                                            }
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-number">
                                                                <div className="data">**** **** **** {item?.card?.last4}</div>
                                                            </div>
                                                            <div className="card-details">
                                                                <div className="expiry-date">
                                                                    <div className="card-text">Expires on</div>
                                                                    <div className="data">{item?.card?.exp_month}/{item?.card?.exp_year}</div>
                                                                </div>
                                                                {/* <div className="cvv-number">   
                                                                    <input type="number" className="input-style" value="" />
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <button 
                                                        className='btn-delete-action' 
                                                        type='button'
                                                        data-toggle="modal" 
                                                        data-target="#ModalCardDelete"
                                                        onClick={() => { setDeleteCardId(item?.id)} } 
                                                        ><i className="far fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                )
                                              }
                                          </div>
                                          {
                                            cardId &&
                                            <Elements stripe={stripePromise}>
                                                <CvcFrom
                                                type="3" 
                                                price={price}
                                                ticket_id={ticket_id}
                                                payment_for='assignee'
                                                payment_type={types}
                                                payment_method={cardId}
                                                />
                                            </Elements>
                                          }
                                          
                                          {/* <div className="add-card-btn">
                                              <button type="button" className="add-action" onClick={() => addNewCart() } >
                                                  <div className="icon"><i className="fa-light fa-plus"></i></div>
                                                  <div className="text">Add new card</div>
                                              </button>
                                          </div> */}
                                      </div>
                              </div>
                              :''
                            }
                            {
                              !isExisting?
                              <div className='payment-card'>
                                  <Elements stripe={stripePromise}>
                                  <CheckoutForm 
                                  type="3" 
                                  price={price}
                                  ticket_id={ticket_id}
                                  payment_for='assignee'
                                  payment_type={types}
                                  />
                                  </Elements>
                              </div>:''
                            }
                          </div>
                          <div className='col-lg-2'>
                              <div className='pay-or'>
                                  or
                              </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='payment-mode'>
                            <PayPalButton
                                  amount={price}
                                  onSuccess={(details, data) => {
                                      const loadPaypalData = async () => {
                                          try {            
                                              const data1 = await storeTlcPaypalData({
                                                  ticket_no:ticket_id,
                                                  paymentData:data,
                                                  paymentDetails : details,
                                                  payment_for: 'assignee',
                                                  payment_type: types,
                                              });  
                                              if(data1.status == 200) {
                                                  //closeOneModal();
                                                  setSearchTicketModal(false)
                                                  return navigate("/authorization-form/"+ticket_id);         
                                              }    
                                          } catch(err) {
                                              console.log(err.message);
                                          }
                                      }
                                      loadPaypalData();
                                  }}
                                  options={{
                                  clientId: paypalClientId()
                                  }}
                              />
                            </div>
                          </div>
                        </div>                      
                     
                      </div>
                    </>
                    }                      
                  </div>
                  <div className="modal-footer justify-content-center">
                    {
                      (ticket_type && ticket_type === 'dmv' && (!ticketInfo || (ticketInfo && ticketInfo.type ===''))) && 
                      <>
                      <button 
                      type="button"
                      className="btn-action"
                      onClick={onSubmit}
                      ><i className="fa-regular fa-arrow-right-to-bracket mr-2"></i>Add Ticket
                      </button>
                      </>
                    }
                    {
                      (ticket_type && ticket_type === 'dmv' && ((ticketInfo && !ticketInfo.is_trans) || !ticketInfo)) && 
                      <>
                      <button 
                      type="button"
                      className="btn-action"
                      onClick={onAddTocart}
                      ><i className="fa-regular fa-arrow-right-to-bracket mr-2"></i>Add & Pay Now
                      </button>
                      </>
                    }
                    {                      
                      ((ticket_type && ticket_type === 'tlc') && !isSattle && trans && trans.length === 0)?
                      <>
                      <button 
                      type="button"
                      className="btn-action"
                      onClick={() => onSattle(1)}
                      ><i className="fa-regular fa-arrow-right-to-bracket mr-2"></i>Settle Ticket
                      </button>
                      <button 
                      type="button"
                      className="btn-action"
                      onClick={() => onSattle(2)}
                      ><i className="fa-regular fa-arrow-right-to-bracket mr-2"></i>Fight Ticket
                      </button>
                      </> :
                      (!isSattle && ticket_type === 'tlc')?
                      <div className='col-lg-12'>
                          <div className='dashboard-action'>
                              <div className="alert alert-warning" role="alert">
                              #{(ticketInfo?.ticket_number)?ticketInfo?.ticket_number:ticketInfo?.summons_number} already paid
                              </div>
                          </div>
                      </div>:''               
                    }
                  </div>
              </div>
          </div>
      </div>
      }
      {
          viewPdf && 
          <div className="modal fade career-apply-modal pdf-modal " id="pdfModal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content">
                      <div className="modal-header">
                          <button type="button" data-dismiss="modal" aria-label="close" className="modal-close" onClick={() => setViewPdf('')}>
                          <i className="fal fa-fw fa-times"></i>
                          </button>
                      </div>
                      <div className="modal-body">
                          <object width="100%" height="100%" data={viewPdf} type="application/pdf">
                              <p>There was an error downloading the invoice.</p>
                          </object>
                      </div>
                  </div>
              </div>
          </div>
      }
      {/* Delete card Modal */}
      <div className="modal fade career-apply-modal delete-modal " id="ModalCardDelete" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content delete">
              <div className="modal-header">
                  <h5 className="modal-title">Card Delete</h5>
                  <button type="button" data-dismiss="modal" aria-label="close" className="modal-close">
                  <i className="fal fa-fw fa-times"></i>
                  </button>
              </div>
              <div className="modal-body">
                  <div className="modal-text">Are you want to delete this card, Click "Confirm" button.</div>
              </div>
              <div className="modal-footer justify-content-center">
                  <button className="cancel-btn mr-4" data-dismiss="modal" type="button">
                  Cancel
                  </button>
                  <button className="confirm-btn" disabled={isDeleteCard} onClick={onStripeCardDelete}>
                  <i className={(isDeleteCard)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-save mr-2"}></i>
                  Confirm
                  </button>
              </div>
              </div>
          </div>
      </div>
      </>
    );
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}
export default connect(mapStateToProps, { storeCart })(SecAddNewTicket);