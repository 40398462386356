import React,{useState,useEffect} from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
import toastr from 'toastr';
import {
  login,
  signup,
  verifyotp,
  role,
  forgetPassword,
  forgetPasswordVerify,
  updatePassword,  
  getUser,
  sendOtp,
  resendOtp,
  autoSignup
} from '../actions/login';
import { getStates,getCountry } from '../actions/site';
import LogoutModal from '../components/LogoutModal';
import InputMask from 'react-input-mask';

const AuthenticationModal = ({ settings,login,auth,getUser,modalActive,autoSignup}) => {
var [navigationShow, setNavigationShow] = useState(false);
var [referred_signup_code, setReferredSignupCode] = useState("");
var [sidenavigationShow, setSidenavigationShow] = useState(false)
const [showPassword,setShowPassword] = useState('fa-regular fa-fw fa-eye-slash');
const [showConfirmPassword,setShowConfirmPassword] = useState('fa-regular fa-fw fa-eye-slash');
const [type,setType] = useState('password');
const [signupType,setSignupType] = useState(2);
const [loginType,setLoginType] = useState(2);
const [confirmtype,setConfirmType] = useState('password');
const [phone,setPhone] = useState("");
const [phoneCode,setPhoneCode] = useState("");
const [searchPhoneCode, setSearchPhoneCode] = useState([]);
const [displayPhoneCode,setDisplayPhoneCode] =  useState('Phone code');
const [phoneCodes,setphoneCodes] = useState({});
const [email,setEmail] = useState("");

const [password,setPassword] = useState("");
const [new_password,setNewPassword] = useState("");
const [confirm_password,setConfirmPassword] = useState("");
const [name,setName] = useState("");
const [otp,setOtp] = useState("");
const [roles,setRole] = useState("");
const navigate = useNavigate();
const [active,setActive] = useState('signin');
const [error, setError] = useState("");
const [users,setUsers] = useState('');

const [isOtp,setIsOtp] = useState(false);
const [isPhoneLogin,setIsPhoneLogin] =  useState(2);
const [phoneOtp,setPhoneOtp] = useState('');

const [countDown,setCountDown] = useState('(00:00)');
const [isResentOtp,setIsResentOtp] =  useState(false);
const [isEnabledResentOtp,setIsEnabledResentOtp] =  useState(false);

const [isVerify,setIsVerify] = useState(false);
const [isEmailLogin,setIsEmailLogin] =  useState(2);

const [isSignupEnabled,setSignupEnabled] = useState(false);
const [isSignupVerifyEnabled,setIsSignupVerifyEnabled] = useState(false);
const [isForgetPasswordEnabled,setIsForgetPasswordEnabled] = useState(false);
const [isEnabledUpdatePass,setIsEnabledUpdatePass] = useState(false); 
const [isSignin,setIsSignin] = useState(false);
const [enabledOtp,setEnabledOtp] = useState(false);

const [signupEmail,setSignupEmail] = useState("");
const [forgetPassEmail,setForgetPassEmail] = useState("");
const [signupPhone,setSignupPhone] = useState('');
const [isBack,setIsBack] = useState(false);

const [states,setStates] = useState([]);
const [searchState, setSearchState] = useState([]);
const [plate_no, setPlateNo] = useState('');
const [state, setState] = useState('');
const [displayState,setDisplayState] =  useState('');
const [vehicleInfo,setVehicleInfo] =  useState({});
const [goToDashboard,setGoToDashboard] = useState(false);
const [userId,setUserId] = useState('');

const [isAccecpt,setIsAccecpted] = useState(false);

useEffect(() => {
    setActive('signin');
    setIsVerify(false);
    setName('');
    setPassword('');
    setNewPassword('');
    setEmail(""); 
    setPhone('');
    setSignupEmail('');
    setForgetPassEmail('');
    setConfirmPassword('');
    setSignupPhone('')
    setCountDown('(00:00)');
    setIsPhoneLogin(2);
    setIsEmailLogin(2);
    setPhoneOtp('')
    setOtp('');
    setLoginType(2);
    setPlateNo('');
    setState('');
    setDisplayState('');
 }, [modalActive]); 

  const hadelAccecpt = (e) => {
    if (e.target.checked) {    
      setIsAccecpted(true);
    } else {
      setIsAccecpted(false);
    }
  }

 

useEffect(()=>{
  const loadPhoneCodeData = async () => {
    try {            
      const data = await getCountry({
 
      });  
      if(data.status === 200) {
        setphoneCodes(data.data)
      }    
    } catch(err) {
      console.log(err.message);
    }
}
loadPhoneCodeData();

// State List
const loadStates = async () => {
  try {
    const data = await getStates({
      country_code: "US",
    });
    if (data.status == 200) {
      setStates(data.data);
      setSearchState(data.data);
    }
  } catch (err) {
    console.log(err.message);
  }
};
loadStates();
},[]);

const handleChangeState = (event) => {
  let searchStates = states.filter((item) => { 
      if(item.state_code){ 
          return item.state_code.toLowerCase().includes(event.target.value.toLowerCase()) || item.name.toLowerCase().includes(event.target.value.toLowerCase())
      }
  })
  setSearchState(searchStates);
}

const handlePShowPassword = () => {
  if(type === 'password'){
    setShowPassword('fa-regular fa-fw fa-eye');
    setType('text');    
  }else{
    setShowPassword('fa-regular fa-fw fa-eye-slash');
    setType('password');
  }
}

const handlePShowConfirmPassword = () => {
  if(confirmtype === 'password'){
    setShowConfirmPassword('fa-regular fa-fw fa-eye');
    setConfirmType('text');    
  }else{
    setShowConfirmPassword('fa-regular fa-fw fa-eye-slash');
    setConfirmType('password');
  }
}

const getState = (id,code) => {
  setState(id);
  setDisplayState(code);
}

//console.log("Data")
const authViewSwitch = types => () => {
  setIsVerify(false);
  if(types === 'signin'){
    setActive('signin');
  }else if(types === 'signup'){
    setActive('signup');
  }else if(types === 'forgot'){
    setActive('forgot');
  }
}
const loginSwitch = types => () => {
  if(types === 'signin'){
    setActive('signin');
  }else if(types === 'signup'){
    setActive('signup');
  }else if(types === 'forgot'){
    setActive('forgot');
  }
}

const  closeOneModal = () => {
  const modal = document.getElementById('authenticationModal');
  modal.classList.remove('show');
  modal.setAttribute('aria-hidden', 'true');
  modal.setAttribute('style', 'display: none');
  const modalBackdrops = document.getElementsByClassName('modal-backdrop');
  document.body.removeChild(modalBackdrops[0]);
}

const handleChangePhoneCode = (event) => {
  let searchPhoneCode = phoneCodes.filter((item) => { 
      if(item.phone_code){ 
          return item.phone_code.toLowerCase().includes(event.target.value.toLowerCase())
      }
  })
  setSearchPhoneCode(searchPhoneCode);
}

const onOtp = async (type) => {
  setEnabledOtp(true);
  if(type === 1){
    try {
      let msg = await sendOtp({ 
        email:email,
        type:loginType,
      });
      if(msg.status === 200){   
        setIsEmailLogin(1);  
        setIsOtp(true);
        otpCounter();
        setEnabledOtp(false);
        setIsVerify(false);
        toastr.success(msg.message);
      }else{
        setIsEmailLogin(2);
        setIsOtp(false);
        setEnabledOtp(false);
        toastr.error(msg.message);
      }      
    } catch(err) {
      setIsEmailLogin(2);
      setIsOtp(false);
      setEnabledOtp(false);
      toastr.error(err.message);
    }
  }else{
    try {
      let msg = await sendOtp({ 
        phone:(phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
        type:loginType,
      });
      if(msg.status === 200){   
        setIsPhoneLogin(1);  
        otpCounter();
        setEnabledOtp(false);
        toastr.success(msg.message);
      }else{
        setIsPhoneLogin(2);
        setEnabledOtp(false);
        toastr.error(msg.message);
      }      
    } catch(err) {
      setIsPhoneLogin(2);
      setEnabledOtp(false);
      toastr.error(err.message);
    }
  }
}

useEffect(() => {
 if(loginType === 2){
    setIsOtp(true);
 }else{
    setIsOtp(false);
 }
}, [loginType]);

const resendOtpFun = async (type,opt) => {
  setIsEnabledResentOtp(true);
  let forEmail = '';
  let forPhone = '';
  if(opt === 'signup'){
    forEmail = signupEmail;
    forPhone = signupPhone;
  }else{
    forEmail = email;
    forPhone = phone;
  }
  if(type === 1){
    try {
      let msg = await resendOtp({ 
        email : forEmail,
        type:type,
      });
      if(msg.status === 200){ 
        otpCounter();
        setIsEnabledResentOtp(false);
        toastr.success(msg.message);
      }else{
        setIsEnabledResentOtp(false);
        toastr.error(msg.message);
      }      
    } catch(err) {
      setIsEnabledResentOtp(false);
      toastr.error(err.message);
    }
  }else{
    try {
      let msg = await resendOtp({ 
        phone:(forPhone)?forPhone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
        type:type,
      });
      if(msg.status === 200){ 
        otpCounter();
        setIsEnabledResentOtp(false);
        toastr.success(msg.message);
      }else{
        setIsEnabledResentOtp(false);
        toastr.error(msg.message);
      }      
    } catch(err) {
      setIsEnabledResentOtp(false);
      toastr.error(err.message);
    }
  }
}


const onSubmit = async (e) => { 
  setError("");
  e.preventDefault(); 
  if(loginType && loginType === 2){
    setIsSignin(true);
    try {
      let msg = await  verifyotp({  
        email : email,
        phone : (phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
        otp : phoneOtp,
        type : loginType
      }); 
      if(msg.status === 200){ 
        try {
          let msg = await login({  
            email : email,
            password : password,
            phone:(phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
            type:loginType,
            otp:phoneOtp,
            cartId:localStorage.getItem('cartId')
          });
      
          if(msg.status === 200 && !msg.data){      
            toastr.success(msg.message);
            closeOneModal();
            return navigate("/dashboard");
          }else if(msg.status === 200 && msg.data){
            setIsVerify(true);
            setIsSignin(false);
          }
          else{
            toastr.error(msg.message);
            setIsSignin(false);
          }      
        } catch(err) {
          toastr.error(err.message);
          setIsSignin(false);
        }
      }else{
        toastr.error(msg.message);
        setIsSignin(false);
      }      
    } catch(err) {
      toastr.error(err.message);
      setIsSignin(false);
    }
  }else{
    setIsSignin(true);  
    if(phoneOtp && phoneOtp != ''){
      try {
        let msg = await  verifyotp({  
          email : email,
          phone : '',
          otp : phoneOtp,
          type : loginType
        }); 
        if(msg.status === 200){ 
          try {
            let msg = await login({  
              email : email,
              password : password,
              phone:(phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
              type:loginType,
              otp:phoneOtp,
              cartId:localStorage.getItem('cartId')
            });
        
            if(msg.status === 200 && !msg.data){      
              toastr.success(msg.message);
              closeOneModal();
              return navigate("/dashboard");
            }else if(msg.status === 200 && msg.data){
              setIsVerify(true);
              setIsSignin(false);
            }
            else{
              toastr.error(msg.message);
              setIsSignin(false);
            }      
          } catch(err) {
            toastr.error(err.message);
            setIsSignin(false);
          }
        }else{
          toastr.error(msg.message);
          setIsSignin(false);
        }      
      } catch(err) {
        toastr.error(err.message);
        setIsSignin(false);
      }
    }else{
      try {
        let msg = await login({  
          email : email,
          password : password,
          phone:(phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
          type:loginType,
          otp:phoneOtp,
          cartId:localStorage.getItem('cartId')
        });
    
        if(msg.status === 200 && !msg.data){      
          toastr.success(msg.message);
          closeOneModal();
          return navigate("/dashboard");
        }else if(msg.status === 200 && msg.data){
          setIsVerify(true);
          setIsSignin(false);
        }
        else{
          toastr.error(msg.message);
          setIsSignin(false);
        }      
      } catch(err) {
        toastr.error(err.message);
        setIsSignin(false);
      }
    }    
  }
}

const onSignup = async (e) => { 
  setError("");
  e.preventDefault();  
  setSignupEnabled(true);
  try {   
      let msg = await  signup({  
        name : name,
        email : signupEmail,
        password : password,
        referred_signup_code:referred_signup_code,
        phone : (signupPhone)?signupPhone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
        // phone_code:phoneCode,
        type:signupType,
        plate_no: plate_no.toUpperCase().replace(/ /g,''),
        state: state,
        is_accecpt:isAccecpt
      }); 
      
      if(msg.status === 200){      
        toastr.success(msg.message);
        otpCounter();
        setActive('otp');
        setEmail(msg.data?.email)
        setSignupType(msg.type)
        setSignupEnabled(false);
      }else{
        toastr.error(msg.message);
        setSignupEnabled(false);
      }     
     
  } catch(err) {
    toastr.error(err.message);
    setSignupEnabled(false);
  }
}

const onForgetPassword = async (e) => { 
  setError("");
  e.preventDefault();   
  setIsForgetPasswordEnabled(true);
  try {
    let msg = await  forgetPassword({  
      email : forgetPassEmail,
    }); 
    if(msg.status === 200){      
      toastr.success(msg.message);
      otpCounter();
      setActive('forget-otp');
      setEmail(msg.data?.email)
      setIsForgetPasswordEnabled(false);
    }else{
      toastr.error(msg.message);
      setIsForgetPasswordEnabled(false);
    }      
  } catch(err) {
    toastr.error(err.message);
    setIsForgetPasswordEnabled(false);
  }
}

const onForgetPasswordVerify = async (e) => { 
  setError("");
  e.preventDefault();   
  try {
    let msg = await  forgetPasswordVerify({  
      email : forgetPassEmail,
      otp : otp
    }); 
    if(msg.status === 200){      
      toastr.success(msg.message);
      setActive('update-password');
    }else{
      toastr.error(msg.message);
    }      
  } catch(err) {
    toastr.error(err.message);
  }
}

const onUpdatePassword = async (e) => { 
  setError("");
  e.preventDefault();   
  setIsEnabledUpdatePass(true);
  try {
    let msg = await  updatePassword({  
      email : forgetPassEmail,
      new_password : new_password,
      confirm_password : confirm_password
    }); 
    if(msg.status === 200){      
      toastr.success(msg.message);
      setActive('signin');
      setEmail(msg.data?.email)
      setIsEnabledUpdatePass(false);
    }else{
      toastr.error(msg.message);
      setIsEnabledUpdatePass(false);
    }      
  } catch(err) {
    toastr.error(err.message);
    setIsEnabledUpdatePass(false);
  }
}

const onVerifyotp = async (e) => { 
  setError("");
  e.preventDefault();   
  setIsSignupVerifyEnabled(true);
  try {
    let msg = await  verifyotp({  
      email : signupEmail,
      phone : (signupPhone)?signupPhone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
      otp : otp,
      type : signupType,
      plate_no: plate_no.toUpperCase().replace(/ /g,''),
      state: state,
      displayState:displayState
    }); 
    if(msg.status === 200){      
      toastr.success(msg.message);
      setIsVerify(false);
      setVehicleInfo(msg.data.vehicleInfo)
      setActive('plate-info');
      setUserId(msg.data.userInfo?._id);
      //setEmail(msg.data?.email);
      setIsSignupVerifyEnabled(false);
    }else{
      toastr.error(msg.message);
      setIsSignupVerifyEnabled(false);
    }      
  } catch(err) {
    toastr.error(err.message);
    setIsSignupVerifyEnabled(false);
  }
}

const onDashboard = async (e) => { 
  setError("");
  e.preventDefault();
  setGoToDashboard(true);
  try {
    let msg = await autoSignup({  
      user_id :  userId,
      plate_no: plate_no.toUpperCase().replace(/ /g,''),
      state: state,
      displayState:displayState
    });

    if(msg.status === 200 && !msg.data){      
      toastr.success(msg.message);
      closeOneModal();
      return navigate("/dashboard");
    }else if(msg.status === 200 && msg.data){
      setIsVerify(true);
      setIsSignin(false);
      setGoToDashboard(false);
    }
    else{
      toastr.error(msg.message);
      setIsSignin(false);
      setGoToDashboard(false);
    }      
  } catch(err) {
    toastr.error(err.message);
    setIsSignin(false);
    setGoToDashboard(false);
  }
}

useEffect(() => {
  // Users
  const ac = new AbortController();
  const loadUserData = async () => {
    try {            
      const data = await getUser(ac.signal);  
      if(data.status === 200) {
          setUsers(data.data)
      }    
    } catch(err) {
      console.log(err.message);
    }
  }
  loadUserData();
}, [localStorage.getItem('access_token')]);


const otpCounter = () => {
  setIsResentOtp(true);
  const count_time5 = add_minutes(new Date(), 0.5).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  const count_time20 = new Date(count_time5).getTime();
  const x20 = setInterval(function() {
    const timezone20 = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    const now20 = new Date(timezone20).getTime();
    const distance20 = count_time20 - now20;
    const days20 = Math.floor(distance20 / (1000 * 60 * 60 * 24));
    const hours20 = Math.floor((distance20 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes20 = Math.floor((distance20 % (1000 * 60 * 60)) / (1000 * 60));
    const seconds20 = Math.floor((distance20 % (1000 * 60)) / 1000);
    // Output the result in an element with id="demo"
    setCountDown('('+ ('0'+minutes20).slice(-2) + ":" + ('0'+seconds20).slice(-2) +')');
    //console.log('('+ ('0'+minutes20).slice(-2) + ":" + ('0'+seconds20).slice(-2) +')');
    // If the count down is over, write some text 
    if (distance20 < 0) {
        clearInterval(x20);
        setCountDown('(00:00)');
        setIsResentOtp(false);
    }
  }, 1000);
} 

const add_minutes = (dt2, minutes2) => {
  return new Date(dt2.getTime() + minutes2*60000);
}



return (
  <>
  {/* Login Modal */}
  <div className="modal authenticationModal fade" id="authenticationModal" tabIndex="-1" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <button type="button" className="modal-close" data-dismiss="modal"><i className="fa-light fa-fw fa-times"></i></button>
        <div className="modal-body">
          <div className="authentication">
            <div className="auth-image "><img src="assets/img/bg-authentication.svg" alt=""/></div>
            <div className="auth-content">
              <div className={`signin-content ${active=='signin'?'active' : ''}`}>
                <h2 className="auth-title">Signin</h2>
                {
                  isVerify && 
                  <div className='col-lg-12'>
                      <div className='dashboard-action'>
                        <div className="alert alert-warning" role="alert">
                          Your account is not verified.If you want to verify <strong onClick={() => onOtp(1)} style={{cursor:'pointer'}}>Click Here</strong> 
                        </div>
                      </div>
                  </div>
                }
                {/* <div className="auth-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda, dicta.</div> */}
                <div className="form-content">
                <div className="commercial-inner login-type">
                  <div className="radio-group">
                    <div className="custom-radio">
                      <input 
                      type="radio" 
                      className={`custom-radio-input ` } 
                      onChange={(e) => {
                        setLoginType(1);
                        setPhone(''); 
                        setIsOtp(false);
                        setIsPhoneLogin(2);
                        setIsEmailLogin(2);
                        setIsVerify(false);
                      }} 
                      checked={loginType==1?'checked' : ''}
                      name="type"
                      value="1"/>
                      <div className="custom-radio-label">With Email</div>
                    </div>
                    <div className="custom-radio">
                      <input 
                      type="radio" 
                      className={`custom-radio-input `}  
                      checked={loginType==2?'checked' : ''}
                      onChange={(e) =>{ 
                        setLoginType(2);
                        setPassword('');
                        setEmail(''); 
                        setIsOtp(true)
                        setIsPhoneLogin(2);
                        setIsEmailLogin(2);
                        setIsVerify(false);
                      }} 
                      name="type" 
                      value="2"/>
                      <div className="custom-radio-label">With Phone</div>
                    </div>
                  </div>
                </div>
                  {
                    (isEmailLogin ===2)?(
                      <>
                      <div className={`form-group ${loginType=== 1?'' : 'd-none'}`}>
                        <div className="input-style">
                          <div className="input-icon"><i className="fa-regular fa-fw fa-envelope"></i></div>
                          <input 
                          className="input" 
                          type="text" 
                          placeholder="Email Address"
                          autoComplete='off'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className={`form-group ${loginType=== 1?'' : 'd-none'}`}>
                        <div className="input-style">
                          <div className="input-icon"><i className="fa-regular fa-fw fa-key"></i></div>
                          <input 
                          className="input" 
                          type={type} 
                          placeholder="Password"
                          autoComplete='off'
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          />
                          <button type="button" className="btn-show-password" onClick={handlePShowPassword} ><i className={showPassword}></i></button>
                        </div>
                      </div>
                      </>
                    ):(
                      (isOtp && isEmailLogin === 1)?
                      <>
                      <div className="input-style">
                        <div className="input-icon"><i className="fa-regular fa fa-mobile"></i></div>
                        <input
                        type="number"
                        className="input" 
                        placeholder={`Enter ${(loginType === 1)?'Email':'SMS'} Verification Code`}
                        value={phoneOtp}
                        onChange={(e) => setPhoneOtp(e.target.value)}
                        />
                      </div>
                      <div className="timer" dangerouslySetInnerHTML={{__html:countDown}}></div>                      
                      </>:''
                    )
                  }
                  <div className={`form-group ${loginType=== 2?'' : 'd-none'}`}>
                    {
                      (isOtp && isPhoneLogin === 2)?
                      <div className="input-style">
                        <div className="input-icon"><i className="fa-regular fa fa-mobile"></i></div>
                        <InputMask
                        mask="(999) 999-9999" 
                        className="input" 
                        placeholder="Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      :
                      <>
                      <div className="input-style">
                        <div className="input-icon"><i className="fa-regular fa fa-mobile"></i></div>
                        <InputMask
                        mask="999999" 
                        type="text"
                        className="input" 
                        placeholder={`Enter ${(loginType === 1)?'Email':'SMS'} Verification Code`}
                        value={phoneOtp}
                        onChange={(e) => setPhoneOtp(e.target.value)}
                        />
                      </div>
                      <div className="timer" dangerouslySetInnerHTML={{__html:countDown}}></div>
                      </>
                    }
                  </div>
                  <div className="form-option">
                    <div className="custom-checkbox">
                      {/* <input type="checkbox" className="custom-checkbox-input"/>
                      <div className="custom-checkbox-label">Remember</div> */}
                    </div>
                    {
                      loginType === 1 &&
                      <button type="button" className="btn-forgot-pasword" onClick={authViewSwitch( "forgot")}>Forgot Password</button>
                    }
                  </div>
                  <div className="form-action">
                    {
                      (isOtp && isPhoneLogin === 2 && isEmailLogin === 2)?
                      <>
                      <button type="button" className="btn-action" onClick={() => onOtp(2)} disabled={enabledOtp}><i className={(enabledOtp)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-arrow-right-to-bracket mr-2'}></i>Verify</button>
                      </>
                      :
                      <>
                      <button type="button" className="btn-action" onClick={onSubmit} disabled={isSignin}><i className={(isSignin)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-arrow-right-to-bracket mr-2'}></i>Signin</button>
                      {
                        (loginType === 2 || isEmailLogin === 1)?
                        <button type="button" className="btn-action" onClick={() => resendOtpFun(loginType,'login')} disabled={isResentOtp}><i className={(isEnabledResentOtp)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-clock mr-2"}></i>Resend OTP</button>:''
                      }                      
                      </>
                    }
                  </div>
                </div>
                <div className="auth-options">Don't have an account? <button type="button" className="btn-signup-trigger"   onClick={authViewSwitch( "signup")}>Signup</button></div>
              </div>
              <div className={`signup-content ${(active==='signup' || active==='otp') ?'active' : ''}`}>
                <h2 className="auth-title">Signup</h2>
                <div className="auth-text"></div>
                <div className="form-content">
                {
                  (active && active === 'signup')?
                  <div className=''>
                  <div className="commercial-inner login-type">
                    <div className="radio-group">
                      <div className="custom-radio">
                        <input 
                        type="radio" 
                        className={`custom-radio-input ` } 
                        onChange={(e) => {
                          setSignupType(1);
                          setSignupPhone('');

                        }} 
                        checked={signupType==1?'checked' : ''}
                        name="type" 
                        value="1"/>
                        <div className="custom-radio-label">With Email</div>
                      </div>
                      <div className="custom-radio">
                        <input 
                        type="radio" 
                        className={`custom-radio-input `}  
                        checked={signupType==2?'checked' : ''}
                        onChange={(e) =>{
                          setSignupType(2);
                          setPassword('');
                          setSignupEmail('');
                        }} 
                        name="type" 
                        value="2"/>
                          <div className="custom-radio-label">With  Phone</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-style">
                      <div className="input-icon"><i className="fa-regular fa-fw fa-user"></i></div>
                      <input 
                      className="input" 
                      type="text" 
                      placeholder="Name"
                      autoComplete='off'                    
                      value={name}
                      onChange={(e) => setName(e.target.value)}/>
                    </div>
                  </div>
                  <div className={`form-group ${signupType=== 1?'' : 'd-none'}`}>
                    <div className="input-style">
                      <div className="input-icon"><i className="fa-regular fa-fw fa-envelope"></i></div>
                      <input  
                      className="input" 
                      autoComplete='off'  
                      type="text" 
                      placeholder="Email Address"
                      value={signupEmail}
                      onChange={(e) => setSignupEmail(e.target.value)}/>
                    </div>
                  </div>
                  <div className={`phone form-row ${signupType=== 2?'' : 'd-none'}`}>
                    {/* <div className="form-group col-md-5">
                      <div className='dropdown state-dropdown'>
                      <button className="dropdown-toggle" type="button" data-toggle="dropdown">{displayPhoneCode}</button>
                        <div className="dropdown-menu">
                          <div className="dropdown-search">
                            <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Search" 
                            onChange={handleChangePhoneCode}
                            />
                          </div>
                          <div className="dropdown-scroll" id="search-state">
                                  {
                              searchPhoneCode.length > 0 &&
                              searchPhoneCode.map((item,index) => (
                                <button 
                                key={index}
                                type="button" 
                                className="dropdown-item" 
                                data-value={item?.phone_code}  
                                data-name={item?.phone_code}
                                onClick={() => {setPhoneCode(item?.phone_code); setDisplayPhoneCode(item?.phone_code)}} 
                                >{item?.phone_code} </button>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group col-md-12">
                      <div className="input-style">
                        <div className="input-icon"><i className="fa-regular fa fa-mobile"></i></div>
                        <InputMask
                        mask="(999) 999-9999"  
                        className="input" 
                        type="text" 
                        placeholder="Phone"
                        autoComplete='off'
                        value={signupPhone}
                        onChange={(e) => setSignupPhone(e.target.value)}/>
                      </div>
                    </div>
                  </div>
                  <div className={`form-group ${signupType=== 1?'' : 'd-none'}`}>
                    <div className="input-style">
                      <div className="input-icon"><i className="fa-regular fa-fw fa-key"></i></div>
                      <input  className="input" 
                      type={type} 
                      placeholder="Password"
                      value={password}
                      autoComplete='off'
                      onChange={(e) => setPassword(e.target.value)}/>
                      <button type="button" className="btn-show-password" onClick={handlePShowPassword} ><i className={showPassword}></i></button>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-style">
                      <div className="input-icon"><i className="fa fa-ticket"></i></div>
                      <input  
                      className="input" 
                      type="text" 
                      placeholder="Enter Plate no"
                      value={plate_no}
                      onChange={(e) => setPlateNo(e.target.value)}/>
                    </div>
                  </div>
                    <div className="form-group">
                      <div className="state">
                          <div className='ticket-dropdown'>
                            <div className='dropdown'>
                                <button type="button" className='dropdown-toggle vehicle-input' data-toggle="dropdown">{(displayState)?displayState:'Select State'}</button>
                                <div className='dropdown-menu'>
                                <div className='dropdown-search'>
                                    <input 
                                    type="text" 
                                    className='form-control' 
                                    placeholder="Search" 
                                    id="search-state-input"
                                    onChange={handleChangeState}
                                    />
                                </div>
                                <div className='dropdown-scroll'>
                                    {
                                        searchState.length > 0 &&
                                        searchState.map((item,index) => (
                                            <button  
                                            key={index} 
                                            type="button" 
                                            className='dropdown-item' 
                                            data-value={item?._id} 
                                            onClick={() => getState(item?._id,item?.state_code,item?.name)}                                           
                                            >{item?.state_code} - {item?.name}</button>
                                        ))
                                    }                                    
                                </div>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-style">
                        <div className="input-icon"><i className="fa fa-user-plus"></i></div>
                        <input  className="input" 
                        type="text" 
                        placeholder="Referred code"
                        value={referred_signup_code}
                        onChange={(e) => setReferredSignupCode(e.target.value)}/>
                      </div>
                    </div>
                    {
                      (signupType === 2)?
                      <div className="form-option">
                        <div className="custom-checkbox alt">
                          <input 
                          type="checkbox" 
                          className="custom-checkbox-input"
                          valie={isAccecpt}
                          onChange={hadelAccecpt}
                          />
                          <div className="custom-checkbox-label">Signing up using cell phone you agree to receive automated transactional messages. Terms and Privacy police can be found at <NavLink to="/terms-of-service" target="_blank" className="link">Terms of service</NavLink></div>
                        </div>
                        
                      </div>:''
                    }
                    <div className="form-action">
                      <button type="button" className="btn-action" onClick={onSignup} disabled={isSignupEnabled}><i className={(isSignupEnabled)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-user-plus mr-2"}></i >Signup</button>
                    </div>
                  </div>:''
                }
                {
                    (active && active === 'otp') ?
                    <div className='forgot-content d-block'>
                      <div className="form-group">
                        <div className="input-style">
                          <div className="input-icon"><i className="fa-regular fa-fw fa-key"></i></div>
                          <InputMask
                            mask="999999" 
                            type="text"
                            className="input" 
                            placeholder={`Enter ${(signupType === 1)?'Email':'SMS'} Verification Code`}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            />
                        </div>
                        <div className="timer" dangerouslySetInnerHTML={{__html:countDown}}></div>
                      </div>
                      <div className="form-action">
                        <button type="button" className="btn-action" onClick={onVerifyotp} disabled={isSignupVerifyEnabled}><i className={(isSignupVerifyEnabled)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-key mr-2"}></i>Verify otp</button>
                        <button type="button" className="btn-action" onClick={() => resendOtpFun(signupType,'signup')} disabled={isResentOtp}><i className={(isEnabledResentOtp)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-clock mr-2"}></i>Resend OTP</button>
                        {/* <button type="button" className="btn-action" onClick={() => setActive('signup')} disabled={isBack}><i className={(isBack)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-arrow-left-to-bracket mr-2"}></i>Back</button> */}
                      </div>
                    </div>:''
                } 
                </div>
                <div className="auth-options">Already have an account? <button type="button" className="btn-signin-trigger" onClick={authViewSwitch( "signin")}>Signin</button></div>
              </div>
              {/* <div className={`forgot-content ${active==='otp'?'active' : ''}`}>
                <h2 className="auth-title">Verification Code </h2>
                <div className="auth-text"></div>
                <div className="form-content">
                  
                </div>
                <div className="auth-options">Already have an account? <button type="button" className="btn-signup-trigger" onClick={authViewSwitch( "signin")}>Signin</button></div>
              </div> */}
              <div className={`forgot-content plate-info-modal ${active==='plate-info'?'active' : ''}`}>
              <h2 className="auth-title">Plate Info </h2>
              <div className='details'>
                    {
                        (vehicleInfo && Object.keys(vehicleInfo).length>0) ?(
                        <div className='details-card'>
                            <div className='vehicle-name'>{vehicleInfo?.name}</div>
                            <div className='vehicle-info'>
                                <div className='vehicle-number'>{vehicleInfo?.plate_no}</div>
                            </div>
                            {
                                vehicleInfo &&
                                <div className='vehicle-metalist'>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Make</div>
                                    <div className='meta-data'>{vehicleInfo?.make}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Model</div>
                                    <div className='meta-data'>{vehicleInfo?.model}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Year</div>
                                    <div className='meta-data'>{vehicleInfo?.year}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Color</div>
                                    <div className='meta-data'>{(vehicleInfo)?vehicleInfo?.color:'N/A'}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Drive Type</div>
                                    <div className='meta-data'>{vehicleInfo?.driveType}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Engine</div>
                                    <div className='meta-data'>{vehicleInfo?.engine}</div>
                                    </div>
                                    <div className='meta-item item-two-third'>
                                    <div className='meta-label'>Fuel</div>
                                    <div className='meta-data'>{vehicleInfo?.fuel}</div>
                                    </div>
                                    <div className='meta-item item-full'>
                                    <div className='meta-label'>VIN/SN</div>
                                    <div className='meta-data'>{vehicleInfo?.vin}</div>
                                    </div>                       
                                    <div className='meta-item'>
                                    <div className='meta-label'>Style</div>
                                    <div className='meta-data'>{vehicleInfo?.style}</div>
                                    </div>
                                    <div className='meta-item item-two-third'>
                                    <div className='meta-label'>Transmission</div>
                                    <div className='meta-data'>{vehicleInfo?.transmission}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Trim</div>
                                    <div className='meta-data'>{vehicleInfo?.trim}</div>
                                    </div>
                                </div>
                            }
                        </div>
                        ):''
                    } 
                    <div className='submit-action mt-3'>
                        <button type="button" className='btn-submit-action' disabled={goToDashboard} onClick={onDashboard}>
                        <i className={(goToDashboard)?'fa-regular fa-spinner fa-spin mr-2':"fa-solid fa-save mr-2"}></i> Go To Dashboard
                        </button>
                    </div>
                </div>
              </div>
              <div className={`forgot-content ${active==='forgot'?'active' : ''}`}>
                <h2 className="auth-title">Forgot Password</h2>
                <div className="auth-text"></div>
                <div className="form-content">
                  <div className="form-group">
                    <div className="input-style">
                      <div className="input-icon"><i className="fa-regular fa-fw fa-envelope"></i></div>
                      <input  className="input" 
                      type="text" 
                      placeholder="Email Address"
                      value={forgetPassEmail}
                      onChange={(e) => setForgetPassEmail(e.target.value)}/>
                    </div>
                  </div>
                  <div className="form-action">
                    <button type="button" className="btn-action" onClick={onForgetPassword} disabled={isForgetPasswordEnabled}><i className={(isForgetPasswordEnabled)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-key mr-2"}></i>Reset Password</button>
                  </div>
                </div>
                <div className="auth-options">Already have an account? <button type="button" className="btn-signup-trigger" onClick={authViewSwitch("signin")}>Signin</button></div>
              </div>
              <div className={`forgot-content ${active=='forget-otp'?'active' : ''}`}>
              <h2 className="auth-title">Verify Otp</h2>
                <div className="auth-text"></div>
                <div className="form-content">
                  <div className="form-group">
                    <div className="input-style">
                      <div className="input-icon"><i className="fa-regular fa-fw fa-key"></i></div>
                      <InputMask
                      mask="999999"  
                      className="input" 
                      type="text" 
                      placeholder="Verification Code"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)} />
                    </div>
                    <div className="timer" dangerouslySetInnerHTML={{__html:countDown}}></div>
                  </div>
                  <div className="form-action">
                    <button type="button" className="btn-action" onClick={onForgetPasswordVerify}><i className="fa-regular fa-key mr-2"></i>Verify otp</button> 
                    <button type="button" className="btn-action" onClick={() => resendOtpFun(1)} disabled={isResentOtp}><i className={(isEnabledResentOtp)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-clock mr-2"}></i>Resend OTP</button>    
                    {/* <button type="button" className="btn-action" onClick={() => setActive('forgot')} disabled={isBack}><i className={(isBack)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-arrow-left-to-bracket mr-2"}></i>Back</button>                */}
                  </div>
                </div>
                <div className="auth-options">Already have an account? <button type="button" className="btn-signup-trigger" onClick={authViewSwitch("signin")}>Signin</button></div>
              </div>
              <div className={`forgot-content ${active=='update-password'?'active' : ''}`}>
                <h2 className="auth-title">Update Password</h2>
                <div className="auth-text"></div>
                <div className="form-content">
                  <div className="form-group">
                    <div className="input-style">
                      <div className="input-icon"><i className="fa-regular fa-fw fa-key"></i></div>
                      <input  className="input" 
                      type={type} 
                      placeholder="New password"
                      value={new_password}
                      onChange={(e) => setNewPassword(e.target.value)}/>
                      <button type="button" className="btn-show-password" onClick={handlePShowPassword} ><i className={showPassword}></i></button>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-style">
                      <div className="input-icon"><i className="fa-regular fa-fw fa-key"></i></div>
                      <input  className="input" 
                      type={confirmtype} 
                      placeholder="Confirm Password"
                      value={confirm_password}
                      onChange={(e) => setConfirmPassword(e.target.value)}/>
                      <button type="button" className="btn-show-password" onClick={handlePShowConfirmPassword} ><i className={showConfirmPassword}></i></button>
                    </div>
                  </div>
                  <div className="form-action">
                    <button type="button" className="btn-action" onClick={onUpdatePassword} disabled={isEnabledUpdatePass}><i className={(isEnabledUpdatePass)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-key mr-2"}></i>Update Password</button>
                  </div>
                </div>
                <div className="auth-options">Already have an account? <button type="button" className="btn-signup-trigger" onClick={authViewSwitch( "signin")}>Signin</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LogoutModal />
  </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}
export default connect(mapStateToProps, { login,getUser,autoSignup })(AuthenticationModal);